

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({
  name: 'horizontal-menu-item-link',
})
export default class HorizontalMenuItemLink extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly active: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: Object })
  public readonly to: Location;

}
