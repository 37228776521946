





import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'icon-menu-arrow-left',
})
export default class IconMenuArrowLeft extends Vue {
}
