

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'horizontal-menu-item',
})
export default class HorizontalMenuItem extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly active: boolean;

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  public onClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.$emit('click', event);
    }
  }

}
