

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import IconMenuArrowLeft from '@/_modules/icons/components/icon-menu-arrow-left.vue';

@Component({
  name: 'horizontal-menu-arrow-left-link',
  components: {
    IconMenuArrowLeft,
  },
})
export default class HorizontalMenuArrowLeftLink extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: Object })
  public readonly to: Location;

}
