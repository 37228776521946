

import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';

@Component({
  name: 'horizontal-menu',
})
export default class HorizontalMenu extends Vue {
}
