

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import IconMenuArrowRight from '@/_modules/icons/components/icon-menu-arrow-right.vue';
import { Location } from 'vue-router';

@Component({
  name: 'horizontal-menu-arrow-right-link',
  components: {
    IconMenuArrowRight
  }
})
export default class HorizontalMenuArrowRightLink extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  @Prop({ type: Object })
  public readonly to: Location;

}
