<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9937 3.21353L14.0876 9.65809L14.1718 9.91721H14.4443H21.2205L15.7384 13.9002L15.518 14.0603L15.6022 14.3194L17.6962 20.764L12.2141 16.781L11.9937 16.6209L11.7733 16.781L6.29118 20.764L8.38515 14.3194L8.46934 14.0603L8.24892 13.9002L2.76685 9.91721H9.54306H9.81552L9.89971 9.65809L11.9937 3.21353Z"
      :stroke="color"
      stroke-width="0.75"
    />
    <path
      v-if="isActive"
      d="M11.9937 3.21353L14.0876 9.65809L14.1718 9.91721H14.4443H21.2205L15.7384 13.9002L15.518 14.0603L15.6022 14.3194L17.6962 20.764L12.2141 16.781L11.9937 16.6209L11.7733 16.781L6.29118 20.764L8.38515 14.3194L8.46934 14.0603L8.24892 13.9002L2.76685 9.91721H9.54306H9.81552L9.89971 9.65809L11.9937 3.21353Z"
      :fill="color"
      :stroke="color"
      stroke-width="0.75"
    />
  </svg>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'icon-star-toggler',
  props: {
    color: {
      type: String,
      default: 'black',
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
});
</script>
