

import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import IconMenuArrowRight from '@/_modules/icons/components/icon-menu-arrow-right.vue';

@Component({
  name: 'horizontal-menu-arrow-right',
  components: {
    IconMenuArrowRight
  }
})
export default class HorizontalMenuArrowRight extends Vue {

  @Prop({ type: Boolean, default: false })
  public readonly disabled: boolean;

  public onClick(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    if (!this.disabled) {
      this.$emit('click', event);
    }
  }
}
